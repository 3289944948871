<template>
  <v-card
    elevation="0"
    @click="open = true"
    style="cursor: pointer"
    @mouseover="animation = 'animate__pulse animate__animated animate__faster'"
    @mouseleave="animation = ''"
    :class="animation"
    max-width="230"
  >
    <v-row align="center" justify="center" no-gutters>
      <v-col cols="12">
        <v-row align="center" justify="center" no-gutters>
          <v-img :src="cliente.media_url" width="230" height="230" contain>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="info"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-row>

        <v-row align="center" justify="center" no-gutters>
          <span class="body-2 title--text font-weight-light text-justify">
            {{ fixDescription(cliente.caption) }}
          </span>
        </v-row>
      </v-col>
    </v-row>
    <cliente-detalle v-model="open" :cliente="cliente"></cliente-detalle>
  </v-card>
</template>

<script>
import ClienteDetalle from '@/components/cliente/clienteDetalle';

export default {
  name: 'clienteDisplay',
  components: {ClienteDetalle},
  props: {
    cliente: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    animation: '',
    open: false,
  }),
  methods: {
    fixDescription(description) {
      if (description.length < 100) return description;
      return description.substring(0, 100) + '...';
    },
  },
};
</script>

<style scoped></style>
