<template>
  <div style="background-color: white">
    <vue-headful
      title="Clientes - Automotriz Petersen"
      description="Nuestros Clientes Satisfechos por alcanzar sus sueños"
    />

    <v-col cols="12">
      <v-row
        v-if="instagramPost.length"
        align="center"
        justify="center"
        no-gutters
      >
        <cliente-display
          v-for="item of instagramPost"
          :key="item.id"
          class="pl-3 pr-3"
          :cliente="item"
        ></cliente-display>
      </v-row>
      <div v-else class="d-flex flex-column" :style="{'min-height': calc}">
        <v-row
          align="center"
          justify="center"
          no-gutters
          class="align-self-center"
        >
          Vuelve Mas Tarde Para ver Novedades!!!.
        </v-row>
      </div>
    </v-col>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ClienteDisplay from '@/components/cliente/clienteDisplay';

export default {
  name: 'Clientes',
  components: {ClienteDisplay},
  computed: {
    ...mapState(['instagramPost']),
  },
  data: () => ({
    calc: 'calc(60vh) !important',
  }),
};
</script>
